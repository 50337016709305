import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstratInput, {
  InputProps as AbstratInputprops,
} from '~/components/Abstracts/Form/Input'
import {
  TInputPresetsKey,
  useInputPreset,
} from '~/components/Abstracts/Form/Input/hooks'
import ErrorList from '~/components/Form/ErrorList'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface InputProps extends AbstratInputprops {
  className?: string
  inputClassName?: string
  preset?: TInputPresetsKey
  inputStyle?: string
  textPreset?: string
  textStyling?: string
  color?: string
}

function Input({
  className,
  inputClassName,
  errorClassname,
  preset,
  textPreset,
  textStyling,
  color,
  ...rest
}: InputProps) {
  const defaultInputStyle = useStyle({
    textPreset: textPreset ?? GlobalTextPreset.Input12_14Haffer,
    textStyling,
    color: color ?? GlobalThemeColors.MineShaft,
  })

  const props = useInputPreset(preset, rest)

  return (
    <div className={cx(css.Input, className, css?.[preset])}>
      <AbstratInput
        className={cx(css.input, defaultInputStyle, inputClassName)}
        errorClassname={cx(css.error, errorClassname)}
        {...props}
      />
      <ErrorList className={cx(css.errorList)} name={props.name} />
    </div>
  )
}

Input.defaultProps = {}

export default Input
