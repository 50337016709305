import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import { useCallback, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import { asText } from '~/components/Abstracts/Prismic/rich-text'
import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import {
  useVideoState,
  VideoControls,
  VideoControlsProps,
  VideoPlayerMock,
  VideoPlayerProps,
} from '~/components/Abstracts/VideoPlayer'
import { PlayRoundedIcon } from '~/components/Icons'
import { ImageProps, Sizes } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useDestructSeconds from '~/hooks/useDestructSeconds'
import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { isRTFilled } from '~/utils/check-empty-string'
import { preprendDigit } from '~/utils/prepend-number'

import css from './styles.module.scss'

const VideoPlayer = dynamic(
  () => import('~/components/Abstracts/VideoPlayer'),
  { ssr: false },
)

const cx = classnames.bind(css)

export type VideoProps = VideoPlayerProps & {
  className?: string
  mockClassName?: string
  imagePoster?: ImageProps
  name?: RichTextBlocks
  controlsTheme?: VideoControlsProps['theme']
  disableGrid?: boolean
  videoPlayerClassName?: string
  sizesFromBreakpoints?: Sizes
  triggerOnce?: boolean
  asPlaceholder?: boolean
  priority?: boolean
  disableMock?: boolean
  disableControls?: boolean
  hasInView?: boolean
  onHidePoster?: (state?: boolean) => void
}

function Video({
  className,
  mockClassName,
  imagePoster,
  name,
  controlsTheme,
  disableGrid,
  videoPlayerClassName,
  ratio,
  sizesFromBreakpoints,
  triggerOnce = false,
  hasInView = true,
  asPlaceholder,
  priority = false,
  disableMock = false,
  onHidePoster,
  disableControls = false,
  ...rest
}: VideoProps) {
  const { ref: trackingPromotionRef } = useTrackingPromotionImpression()
  const isMobile = useBreakpoint(GlobalBreakpoints.MD)
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24HafferSemiBold,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.White,
  })
  const durationStyle = useStyle({
    textPreset: GlobalTextPreset.Title12_14Haffer,
    color: GlobalThemeColors.White,
  })

  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: true,
  })

  const containerRef = useRef()

  const setRef = useCallback(
    (node) => {
      ref(node)
      trackingPromotionRef?.(node)
      containerRef.current = node
    },
    [ref, trackingPromotionRef, containerRef],
  )

  const [
    {
      videoIsLoading,
      videoIsReady,
      videoMuted,
      videoPaused,
      videoProgress,
      videoDuration,
      videoIsHover,
      videoIsFullScreen,
      videoHandlePausePlay,
      videoHandleMuteUnmute,
      videoHandleSeekTo,
      videoHandleFullScreen,
    },
    setVideoState,
  ] = useVideoState()

  const { h, m, s } = useDestructSeconds(videoDuration)

  const hasContent = isRTFilled(name) || Boolean(videoDuration)
  const hasLoading = videoIsLoading && !videoIsReady
  const hide = videoIsReady && !videoPaused
  const shouldShowVideo = hasInView ? inView : true

  // This is required for the event tracking
  const videoNameProps = isRTFilled(name)
    ? {
        videoName: asText(name),
      }
    : {}

  useEffect(() => {
    onHidePoster?.(hide)
  }, [hide, onHidePoster])

  return (
    <div
      className={cx(css.Video, className, !disableGrid && gridStyle)}
      ref={setRef}>
      {shouldShowVideo && (
        <VideoPlayer
          className={cx(css.videoPlayer, videoPlayerClassName)}
          hasPlaysInline={false}
          controls={false}
          setVideoState={setVideoState}
          ratio={ratio ?? css.ratio}
          hasEventTracking
          hasInView={hasInView}
          {...videoNameProps}
          {...rest}>
          <>
            {!disableControls && (
              <VideoControls
                className={cx(css.videoControls)}
                videoIsLoading={videoIsLoading}
                videoMuted={videoMuted}
                videoPaused={videoPaused}
                videoProgress={videoProgress}
                videoDuration={videoDuration}
                videoIsFullScreen={videoIsFullScreen}
                videoIsHover={isMobile ? isMobile : videoIsHover}
                videoHandlePausePlay={videoHandlePausePlay}
                videoHandleMuteUnmute={videoHandleMuteUnmute}
                videoHandleSeekTo={videoHandleSeekTo}
                videoHandleFullScreen={videoHandleFullScreen}
                theme={controlsTheme}
              />
            )}
            {!videoPaused && (
              <span
                className={css.playPauseLayer}
                onClick={videoHandlePausePlay}
              />
            )}
          </>
        </VideoPlayer>
      )}

      {!disableMock && (
        <VideoPlayerMock
          className={cx(css.videoPlayerMock, mockClassName)}
          image={imagePoster}
          hide={hide}
          videoHandlePausePlay={videoHandlePausePlay}
          ratio={ratio ?? css.ratio}
          sizesFromBreakpoints={sizesFromBreakpoints}
          controls={rest?.controls}
          asPlaceholder={asPlaceholder}
          priority={priority}
          icon={
            hasLoading ? (
              <LoadingComponent />
            ) : (
              <PlayRoundedIcon className={cx(css.playRoundedIcon)} />
            )
          }
        />
      )}

      {hasContent && (
        <div className={cx(css.videoContainer, { hide })}>
          <RichText className={cx(css.name, nameStyle)} render={name} />

          <p
            className={cx(css.duration, durationStyle, {
              hide: !videoDuration,
            })}>
            {`(${preprendDigit(h)}:${preprendDigit(m)}:${preprendDigit(s)})`}
          </p>
        </div>
      )}
    </div>
  )
}

Video.defaultProps = {
  disableGrid: false,
}

export default Video
