import classnames from 'classnames/bind'
import React from 'react'
import { useMeasure } from 'react-use'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useParallax, {
  useGetParallaxStyleFromMaxTranslateAndHeight,
} from '~/hooks/useParallax'
import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 24 / 24 }]

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Titlefluid45_150HafferSemiBold,
  [GlobalTextSize.Small]: GlobalTextPreset.Title18_24HafferSemiBold,
}

export interface CoverImageProps {
  className?: string
  image?: ImageProps
  title?: RichTextBlocks
  cta?: SquaredCtaProps
  titleSize?: GlobalTextSize
}

const MAX_TRANSLATE_Y = 100
function CoverImage({
  className,
  image,
  title,
  cta,
  titleSize,
}: CoverImageProps) {
  const { ref: trackingPromotionRef } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)

  const { y, ref } = useParallax({
    displacement: MAX_TRANSLATE_Y,
  })

  const [containerRef, { height }] = useMeasure()

  const parallaxStyle = useGetParallaxStyleFromMaxTranslateAndHeight({
    y,
    maxTranslateY: MAX_TRANSLATE_Y,
    height,
  })

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={trackingPromotionRef}
      className={cx(css.CoverImage, className, gridStyle, titleSizeStyle)}>
      <RichText className={cx(css.title, titleStyle)} render={title} />

      {image && (
        <div className={cx(css.banner)}>
          <div className={css.imageContainer} ref={containerRef}>
            <div ref={ref} style={parallaxStyle}>
              <Image
                className={cx(css.image)}
                sizesFromBreakpoints={IMAGE_SIZES}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={slicePosition === 1}
                {...image}
              />
            </div>
          </div>

          {cta && <SquaredCta className={cx(css.cta)} {...cta} />}
        </div>
      )}
    </div>
  )
}

CoverImage.defaultProps = {
  titleSize: GlobalTextSize.Small,
}

export default CoverImage
