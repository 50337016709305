import classnames from 'classnames/bind'
import React from 'react'
import { useMeasure } from 'react-use'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import ProductCard, { ProductCardProps } from '~/components/Slices/ProductCard'
import Image, { ImageProps } from '~/components/UI/Image'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useParallax, {
  useGetParallaxStyleFromMaxTranslateAndHeight,
} from '~/hooks/useParallax'
import useTrackingProductImpression from '~/hooks/useTrackingProductImpresion'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  IMAGE: [{ breakpoint: 'md', ratio: 14.5 / 24 }, { ratio: 24 / 24 }],
  CARD: [{ breakpoint: 'md', ratio: 7 / 24 }, { ratio: 22 / 24 }],
}

export interface PushProductsImagesProps {
  className?: string
  title?: RichTextBlocks
  description?: RichTextBlocks
  image?: ImageProps
  card?: ProductCardProps
  reversed?: boolean
  textAlign?: GlobalTextAlign
}

const MAX_TRANSLATE_Y = 100
function PushProductsImages({
  className,
  title,
  description,
  image,
  card,
  reversed,
}: PushProductsImagesProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24HafferSemiBold,
    color: GlobalThemeColors.Black,
  })
  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text12_14Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  const { y, ref } = useParallax({
    displacement: MAX_TRANSLATE_Y,
  })

  const [containerRef, { height }] = useMeasure()
  const parallaxStyle = useGetParallaxStyleFromMaxTranslateAndHeight({
    y,
    maxTranslateY: MAX_TRANSLATE_Y,
    height,
  })

  const { ref: trackingProductRef } = useTrackingProductImpression(card)

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={trackingProductRef}
      className={cx(css.PushProductsImages, className, gridStyle, {
        reversed,
      })}>
      <RichText className={cx(css.title, titleStyle)} render={title} />
      <RichText
        className={cx(css.description, descriptionStyle)}
        render={description}
      />

      <div className={cx(css.container)}>
        <div className={css.imageContainer} ref={containerRef}>
          <div ref={ref} style={parallaxStyle}>
            {image && (
              <Image
                className={cx(css.image)}
                ratio={css.imageRatio}
                sizesFromBreakpoints={IMAGE_SIZES.IMAGE}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={slicePosition === 1}
                {...image}
              />
            )}
          </div>
        </div>

        {card && (
          <ProductCard
            className={cx(css.card)}
            ratio={css.imageCard}
            sizesFromBreakpoints={IMAGE_SIZES.CARD}
            priority={slicePosition === 1}
            {...card}
          />
        )}
      </div>
    </div>
  )
}

PushProductsImages.defaultProps = {
  reversed: false,
  textAlign: GlobalTextAlign.AlignCenter,
}

export default PushProductsImages
